<template>
  <div w="full" min-h="full" relative bg="white">
    <div class="h-58 w-full bg-[#07B883]">
      <BusinessBUserHeader />
      <div class="mt-5 px-6 text-center text-white">
        <div class="whitespace-pre-wrap text-xl font-600 leading-tight">
          {{ t('title') }}
        </div>
      </div>
    </div>

    <div class="px-3.5 pt-4.5 -mt-12">
      <div class="rounded-xl bg-white px-4 py-1 shadow-[0_2px_8px_0px_rgba(122,122,122,0.25)]">
        <!-- 选择地址 -->
        <BusinessBSearchDestination class="mx-2 my-4" />
        <div class="van-hairline--top bg-[#B3B3B3]"></div>
        <!-- 选择日期 -->
        <BusinessBSelectDate>
          <template #default="{ formatDate }">
            <div class="flex p-3">
              <img :width="28" src="~assets/icons/icon-calendar.png" />
              <div class="van-hairline--right mx-4 bg-[#B3B3B3]"></div>
              <div class="text-lg text-[#1B1B1B] font-500">
                {{ formatDate }}
              </div>
            </div>
          </template>
        </BusinessBSelectDate>
        <div class="van-hairline--top bg-[#B3B3B3]"></div>
        <!-- 选择门票类型 -->
        <BusinessBSelectTicket :only-read="true">
          <template #default="{ checkedLabel }">
            <div class="flex justify-between p-3">
              <div class="flex flex-1">
                <img :width="28" :height="28" src="~assets/icons/icon-ticket.png" class="flex-shrink-0" />
                <div class="van-hairline--right mx-4 bg-[#B3B3B3]"></div>
                <div class="text-lg text-[#1B1B1B] font-500">
                  {{ checkedLabel }}
                </div>
                <img
                  class="ml-3 flex-shrink-0 self-center"
                  :width="16"
                  :height="16"
                  src="~assets/icons/icon-person.png"
                />
              </div>
              <img
                class="float-right self-center"
                :width="16"
                :height="16"
                src="~assets/icons/icon-arrow-down.png"
              />
            </div>
          </template>
        </BusinessBSelectTicket>
        <div class="van-hairline--top bg-[#B3B3B3]"></div>
        <NuxtLink
          :to="{ path: listRoutePath, query }"
          class="mx-2 my-3 block rounded-full bg-black py-3 text-center text-xl text-white font-800"
        >
          {{ t('searchTicket') }}
        </NuxtLink>
      </div>
    </div>

    <!-- 合作品牌 -->
    <PartnerBrands />
    <div class="van-hairline--top mt-8 bg-[#B3B3B3]"></div>
    <!-- 如何赚取 -->
    <HowToEarn />
    <!-- 底部介绍 -->
    <BusinessBFooter />
  </div>
</template>

<script lang="ts" setup>
import HowToEarn from './_components/HowToEarn.vue'
import PartnerBrands from './_components/PartnerBrands.vue'

const { t } = useI18n()
const { query } = useRoute()

const listRoutePath = computed(() => `/attractions/list/${makeListRoute()}`)
</script>

<style>

</style>

<i18n lang="json">
  {
    "en": {
      "title": "Find the best price \nOver 300,000 attraction tickets, tours and activities in 600 cities",
      "subtitle": "Earn miles/points every time\n you book a ticket",
      "destination": "Search your destination",
      "searchTicket": "Search Tickets"
    }
  }
</i18n>
